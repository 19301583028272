import { NAME as QuantityCounterName } from '@spa-ec/components/QuantityCounter/QuantityCounter.theme';
import { NAME as DesktopHeaderName, HeaderComponents } from '@spa-ec/components/Header/DesktopHeader.theme';
import { NAME as ColumnCardName, ColumnCartLayout } from '@spa-ec/components/Card/ColumnCard.theme';
import { NAME as HeaderName } from '@spa-ec/components/Header/Header.theme';
import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as CategoryListingName } from '@spa-ec/components/CategoryListing/CategoryListing.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import theme from './theme.conf';
import { CategoryListingType } from '@spa-ec/components/CategoryListing/constants';
const contentPageTheme = {
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    showHomepageCategorySection: true,
    linkColor: theme.col.primary.fg,
    linkColorHover: theme.col.secondary.fg,
    sideContentPadding: 'px-4',
};
const headerTheme = {
    showCountrySelector: false,
};
const quantityCounterTheme = {
    maxQuantity: 999,
};
const desktopHeaderTheme = {
    headerComponents: [
        HeaderComponents.SITE_LOGO,
        HeaderComponents.CUSTOMER_TYPE_SELECTOR,
        HeaderComponents.PROMOTIONS,
        HeaderComponents.SEARCH,
        HeaderComponents.MY_ACCOUNT_AND_CART,
    ],
};
const columnCardTheme = {
    layout: ColumnCartLayout.DOUBLE_COLUMN,
};
const categoryListingTheme = {
    listingType: CategoryListingType.SUBCATEGORIES,
    forceShowPagination: true,
};
const pageTitleTheme = {
    noBorder: true,
    showLogoutIcon: false,
    combineSections: true,
};
const cartTableTheme = {
    showProductDetails: true,
    headerColor: 'col-primary',
    showPerKronaInCartTable: false,
};
export default {
    [ContentPageName]: contentPageTheme,
    [QuantityCounterName]: quantityCounterTheme,
    [DesktopHeaderName]: desktopHeaderTheme,
    [ColumnCardName]: columnCardTheme,
    [HeaderName]: headerTheme,
    [CategoryListingName]: categoryListingTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
};
