const themeGen = theme => { // eslint-disable-line
  const res = {
    siteLogo: {
      imagesRootPath: '/images/sitelogo',
      paths: {
        b2c: {
          default: '/lamportillallt_logo.svg',
        },
        b2b: {
          default: '/lamportillallt_logo.svg',
        },
      },
    },
  };
  return res;
};

export default themeGen;
