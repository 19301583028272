// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
/* eslint-disable */
import CartMod from '~/packages/spa-ec/src/components/Cart/CartMod.theme-lamportillallt.js';
import Cheapest from '~/packages/spa-ec/src/displayComponents/PrisjaktCheapest/Cheapest.theme-lamportillallt.js';
import DesktopMenuSubSection from '~/packages/spa-ec/src/components/Header/HeaderDesktopMenu/DesktopMenuSubSection.theme-lamportillallt.js';
import MyPages from '~/packages/spa-ec/src/components/MyPages/MyPages.theme-lamportillallt.js';
import OrderConfirmation from '~/packages/spa-ec/src/components/OrderConfirmation/OrderConfirmation.theme-lamportillallt.js';
import SiteLogo from '~/packages/spa-ec/src/components/Header/HeaderSiteLogo/SiteLogo.theme-lamportillallt.js';
import USPWrapper from '~/packages/spa-ec/src/displayComponents/Cms/USPWrapper.theme-lamportillallt.js';

const overrides = {
  CartMod,
  Cheapest,
  DesktopMenuSubSection,
  MyPages,
  OrderConfirmation,
  SiteLogo,
  USPWrapper,
};
export default overrides;

// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
